import React, { useMemo, useState } from 'react'
import Layout from '../components/Layout'
import { useStaticQuery, graphql } from 'gatsby'
import Box from '../components/fragments/Box'
import SEO from '../components/seo/Seo'

const categories = () => {
  const data = useStaticQuery(graphql`
    query allforCat {
      allGoogleDocs {
        nodes {
          name
          authorID
          description
          categoryID
          createdTime(fromNow: true)
          path
          cover {
            image {
              childImageSharp {
                fluid(maxWidth: 128) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
  `)
  const categories = useMemo(
    () =>
      data.allGoogleDocs.nodes
        .map(e => e.categoryID)
        .filter((v, i, a) => a.indexOf(v) === i),
    data.allGoogleDocs.nodes
  )

  const [articles, setarticles] = useState(
    data.allGoogleDocs.nodes.filter(e => e.categoryID === categories[0])
  )
  const [selectedCategory, setSelectedCategory] = useState('doll')

  const handleOnclick = category => {
    const articlesOfCat = data.allGoogleDocs.nodes.filter(
      e => e.categoryID === category
    )
    setarticles(articlesOfCat)
    setSelectedCategory(category)
  }

  return (
    <Layout>
      <SEO
        title='TOYREADS - all of the feeds categorized'
        pathname='categories'
        desc='quicky search through all of our categorized section'
      />
      <section className='hero is-link mt-6'>
        <div className='hero-body'>
          <div className='container has-text-centered'>
            <p className='title'>Catgories</p>
            <p className='subtitle'>there can never be too many of types</p>
          </div>
        </div>

        <div className='hero-foot'>
          <nav className='tabs is-boxed is-fullwidth'>
            <div className='container'>
              <ul>
                {categories.map((e, i) => (
                  <li
                    key={i}
                    className={selectedCategory == e ? 'is-active' : ''}
                    onClick={() => handleOnclick(e)}
                  >
                    <a>{e.replace('_', ' ').toUpperCase()}</a>
                  </li>
                ))}
              </ul>
            </div>
          </nav>
        </div>
      </section>
      <section className='section'>
        <div className='container'>
          <div className='columns is-multiline'>
            {articles.map((e, i) => (
              <div key={i} className='column is-half'>
                <Box
                  title={e.name}
                  authorID={e.authorID}
                  description={e.description}
                  image={e.cover}
                  path={e.path}
                  date={e.createdTime}
                />
              </div>
            ))}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default categories
